import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { ReactComponent as OrangeLogo } from '../svgs/orange-logo.svg';
import { ReactComponent as GreyLogo } from '../svgs/grey-logo.svg';
import styles from './FaqPage.module.scss';

export default function FaqPage() {
    function setArea(area) {
        localStorage.setItem('area', area);
    }

    return (
        <div className={styles.app}>
            <Helmet>
                <title>FAQ</title>
                <meta name="description" content="Find svar på ofte stillede spørgsmål" />
            </Helmet>
            <div className={styles.menu}>
                <OrangeLogo
                    style={{
                        float: 'left',
                        marginTop: 18,
                        marginLeft: 24,
                        marginBottom: 21,
                    }}
                />
                {/* <div style={{ float: 'right', marginTop: 18, marginRight: 14 }}>
                    <BurgerMenu />
                </div> */}
                <div style={{ clear: 'both', marginLeft: 24 }}>
                    <ul>
                        <li style={{ marginRight: 15 }}><Link to="/" onClick={() => setArea('DK2')}>Øst</Link></li>
                        <li style={{ marginRight: 15 }}><Link to="/" onClick={() => setArea('DK1')}>Vest</Link></li>
                        <li><Link className={styles.active} to="faq">FAQ</Link></li>
                    </ul>
                </div>
            </div>
            <div className={styles.content}>
                <div style={{ maxWidth: '785px', margin: 'auto', paddingLeft: 30 }}>
                    <div className={styles.question}>Hvor kommer priserne og agifterne fra?</div>
                    <div className={styles.answer}>Priserne og afgifterne bliver hentet fra <a target="_blank" rel="noopener noreferrer" href="https://www.energidataservice.dk">Energi Data Service</a></div>
                    <br />
                    <div className={styles.question}>Hvad er dækker variable tarif over?</div>
                    <div className={styles.answer}>Den variable tarif er en tarif(gebyr), som man betaler pr kWh man forbruger. Hos langt de fleste selskaber varierer denne tarif i løbet af dagen. Du kan læse mere om den variable tarif hos dit netslskab fx hos <a target="_blank" rel="noopener noreferrer" href="https://radiuselnet.dk/elnetkunder/tariffer-og-netabonnement/">Radius</a></div>
                </div>
            </div>
            <div className={styles.bottomMenu}>
                <GreyLogo
                    style={{
                        marginTop: 25,
                    }}
                />
            </div>
        </div>
    );
}
