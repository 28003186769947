import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { ReactComponent as OrangeLogo } from './svgs/orange-logo.svg';
import { ReactComponent as GreyLogo } from './svgs/grey-logo.svg';
import styles from './App.module.scss';

function App() {
    const [loading, setLoading] = useState(true);
    const [dataTimeStamp, setDataTimeStamp] = useState(new Date());
    const [area, setArea] = useState(() => {
        const localStorageArea = localStorage.getItem('area');

        function isNullOrWhitespace(input) {
            if (input === null || input === undefined) return true;

            return input.replace(/\s/g, '').length < 1;
        }
        if (isNullOrWhitespace(localStorageArea) || (localStorageArea !== 'DK2' && localStorageArea !== 'DK1')) return 'DK2';

        return localStorageArea;
    });
    const [gln, setGln] = useState(() => {
        const localStorageGln = localStorage.getItem('gln');

        function isNullOrWhitespace(input) {
            if (input === null || input === undefined) return true;

            return input.replace(/\s/g, '').length < 1;
        }
        if (isNullOrWhitespace(localStorageGln)) return -1;

        return localStorageGln;
    });

    const [glnNumbers, setGlnNumbers] = useState(null);
    const [elpriserToday, setElpriserToday] = useState(null);
    const [elpriserTomorrow, setElpriserTomorrow] = useState(null);
    const exchangeRate = 7.4413;
    const currentHour = (new Date()).getHours();
    const minPrice = Math.min(...[...elpriserToday?.filter(x => x.hour >= currentHour)?.map(x => x.total) ?? [100], ...elpriserTomorrow?.map(x => x.total) ?? [100]]);
    const maxPrice = Math.max(...[...elpriserToday?.filter(x => x.hour >= currentHour)?.map(x => x.total) ?? [0], ...elpriserTomorrow?.map(x => x.total) ?? [0]]);
    const isTomrrowNull = elpriserTomorrow?.[0]?.total === undefined || elpriserTomorrow?.[0]?.total === null;

    useEffect(() => {
        // Update localStorage area
        localStorage.setItem('area', area);
        localStorage.setItem('gln', gln);

        // Get prices for the selected area
        async function getElpriser() {
            setLoading(true);
            const now = new Date();
            now.setDate(now.getDate());
            const cutoff = new Date();
            cutoff.setDate(cutoff.getDate() + 2);
            const elpriserUrl = `https://elpriserligenu-xrxv3.ondigitalocean.app/electricityprices${area}`;
            const response = await fetch(elpriserUrl);
            const data = await response.json();
            const timeStamp = new Date(data.timeStamp);
            setDataTimeStamp(timeStamp);

            const glnUrl = 'https://elpriserligenu-xrxv3.ondigitalocean.app/chargeOwners';
            const glnResponse = await fetch(glnUrl);
            const glnData = await glnResponse.json();
            setGlnNumbers(glnData);

            let localTarifs = null;
            const tarifUrl = `https://elpriserligenu-xrxv3.ondigitalocean.app/tarrifs/${gln}`;
            const tarifResponse = await fetch(tarifUrl);
            const tarifData = await tarifResponse.json();
            localTarifs = tarifData;

            const unsortedElpriser = data.records.map(x => ({ ...x, hourDK: new Date(x.hourDK) }));

            unsortedElpriser.sort((a, b) => a.hourDK - b.hourDK);
            const sorted = unsortedElpriser
                .map(x => ({
                    ...x,
                    hour: (x.hourDK).getHours() < 10 ? `0${(x.hourDK).getHours()}` : (x.hourDK).getHours(),
                    spotPriceDKK: x.spotPriceEUR * exchangeRate / 1000,
                }));

            const tomorrow = new Date();
            tomorrow.setDate(tomorrow.getDate() + 1);
            setElpriserToday(sorted
                .filter(x => (x.hourDK).getDate() === now.getDate())
                .map(x => ({ ...x, tarif: localTarifs?.today !== null ? localTarifs?.today?.[`price${(x.hourDK).getHours() + 1}`] : 0 }))
                .map(x => ({
                    ...x,
                    total: x.spotPriceEUR === null || x.spotPriceEUR === undefined ? null : x.spotPriceDKK + x.tarif,
                })));
            setElpriserTomorrow(sorted
                .filter(x => (x.hourDK).getDate() === tomorrow.getDate())
                .map(x => ({ ...x, tarif: localTarifs?.tomorrow !== null ? localTarifs?.tomorrow?.[`price${(x.hourDK).getHours() + 1}`] : 0 }))
                .map(x => ({
                    ...x,
                    total: x.spotPriceEUR === null || x.spotPriceEUR === undefined ? null : x.spotPriceDKK + x.tarif,
                })));
            setLoading(false);
        }
        getElpriser();
    }, [area, gln]);

    function getPercentage(value) {
        return 100 - (value - minPrice) / (maxPrice - minPrice) * 100;
    }

    function perc2color(perc) {
        let r;
        let g;
        const b = 0;
        if (perc < 50) {
            r = 255;
            g = Math.round(5.1 * perc);
        } else {
            g = 255;
            r = Math.round(510 - 5.10 * perc);
        }
        const h = r * 0x10000 + g * 0x100 + b * 0x1;
        return `#${(`000000${h.toString(16)}`).slice(-6)}`;
    }

    function addDigits(value, digits) {
        return value.toLocaleString('da', { minimumFractionDigits: digits, maximumFractionDigits: digits });
    }

    return (
        <div className={styles.app}>
            <Helmet>
                <title>Elpriser lige nu</title>
                <meta name="description" content="Se elpriserne time for time for i dag og i morgen let og overskueligt for øst (DK1) og vest (DK2)" />
            </Helmet>
            <div className={styles.menu}>
                <OrangeLogo
                    style={{
                        float: 'left',
                        marginTop: 18,
                        marginLeft: 24,
                        marginBottom: 21,
                    }}
                />
                <div style={{ clear: 'both', marginLeft: 24 }}>
                    <ul>
                        <li style={{ marginRight: 15 }}><button type="button" className={area === 'DK2' ? styles.active : null} onClick={() => setArea('DK2')}>Øst</button></li>
                        <li style={{ marginRight: 15 }}><button type="button" className={area === 'DK1' ? styles.active : null} onClick={() => setArea('DK1')}>Vest</button></li>
                        <li><Link to="faq">FAQ</Link></li>
                    </ul>
                </div>
            </div>
            <div className={styles.content}>
                <div className={styles.priceFont} style={{ marginBottom: 5 }}>Vælg om du bor øst (DK2) eller vest (DK1) for storebælt&nbsp;
                    <select value={gln} onChange={e => setGln(e.target.value)}>
                        <option key={-1} value={-1}>Vælg dit netselskabet for tarif</option>
                        { !loading && glnNumbers?.map((x, index) => (
                            <option key={x.gln} value={x.gln}>{x.company}</option>
                        )) }

                    </select>
                </div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        maxWidth: 345,
                        paddingLeft: 5,
                        paddingRight: 5,
                        margin: 'auto',
                        fontWeight: '800',
                        fontSize: '14px',
                        lineHeight: '17px',
                        letterSpacing: '0.175em',
                        textTransform: 'uppercase',

                    }}
                >
                    <div
                        style={{
                            width: '30px',
                            marginRight: 20,
                            textAlign: 'center',
                        }}
                    >
                        Tid
                    </div>
                    <div style={{ width: '130px', marginRight: 25, textAlign: 'center' }}>I dag</div>
                    <div style={{ width: '130px', textAlign: 'center' }}>I morgen</div>
                </div>

                { loading && (
                    <div
                        style={{
                            margin: 'auto',
                            maxWidth: 300,
                            border: '1px solid #ccc',
                            borderRadius: 16,
                        }}
                    >
                        Indlæser data!
                    </div>
                )}
                { !loading && elpriserToday?.map((x, index) => {
                    const tomorrow = elpriserTomorrow.find(y => y.hour === x.hour);
                    const valueToday = addDigits(Math.round((x.total) * 100) / 100, 2);
                    const valueTomorrow = (tomorrow?.spotPriceDKK === null || tomorrow?.spotPriceDKK === undefined)
                        ? null
                        : addDigits(Math.round((tomorrow.total) * 100) / 100, 2);
                    const numberHour = Number(x.hour);
                    return (
                        <div
                            key={x.hourDK}
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                maxWidth: 345,
                                paddingLeft: 5,
                                paddingRight: 5,
                                margin: 'auto',
                                // marginLeft: 10,
                                // marginRight: 10,
                                background: numberHour === currentHour ? '#CCCCCC' : null,
                                borderRadius: numberHour === currentHour ? '100px' : null,
                            }}
                        >
                            <div
                                className={styles.priceFont}
                                style={{
                                    width: '30px',
                                    marginRight: 20,
                                    textAlign: 'center',
                                    marginBottom: '2.5px',
                                    marginTop: '2.5px',
                                    padding: '2px',
                                }}
                            >
                                {x.hour}
                            </div>
                            <div
                                className={styles.priceFont}
                                style={{
                                    width: '130px',
                                    padding: '2px',
                                    marginBottom: '2.5px',
                                    marginTop: '2.5px',
                                    marginRight: 24,
                                    borderRadius: '30px',
                                    backgroundColor: numberHour < currentHour ? 'rgba(47, 79, 79, 0.8)' : perc2color(getPercentage(x.total)),
                                }}
                            >
                                {valueToday} kr
                            </div>
                            <div
                                className={styles.priceFont}
                                style={{
                                    width: '130px',
                                    padding: '2px',
                                    marginBottom: '2.5px',
                                    marginTop: '2.5px',
                                    borderRadius: '30px',
                                    backgroundColor: valueTomorrow === null ? 'white' : perc2color(getPercentage(tomorrow?.total ?? 0)),
                                }}
                            >
                                {valueTomorrow === null ? 'Ikke oplyst*' : `${valueTomorrow} kr`}
                            </div>
                        </div>
                    );
                }) }
                { !loading && (
                    <div
                        style={{
                            fontSize: 12,
                            lineHeight: '14px',
                            color: '#939393',
                        }}
                    >
                        Data opdateret {dataTimeStamp.toLocaleString('da-DK')}
                        {isTomrrowNull && (
                            <>
                                <br />
                                *Priserne for i morgen er typisk først tilgængelig efter kl 13:00
                            </>
                        )}
                    </div>
                )}
                <div
                    style={{
                        fontWeight: 400,
                        fontSize: 16,
                        margin: '10px auto',
                        padding: '0px 50px',
                    }}
                >
                    {gln !== '-1' && (
                        <>Alle priser er den rå elpris i kr pr kWh uden moms og inklusiv den variable tarif</>
                    )}
                    {gln === '-1' && (
                        <>Alle priser er den rå elpris i kr pr kWh uden moms og afgifter</>
                    )}
                </div>
                <div
                    style={{
                        fontWeight: 400,
                        fontSize: 12,
                        margin: '10px auto',
                        padding: '0px 50px',
                    }}
                >
                    Oplever du problemer med siden så kan du sende en email til: elpriserligenu@gmail.com
                </div>
            </div>
            <div className={styles.bottomMenu}>
                <GreyLogo
                    style={{
                        marginTop: 25,
                    }}
                />
            </div>
        </div>
    );
}

export default App;
